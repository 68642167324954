<template>
  <div :class="position">
    <b-card :bg-variant="variant" text-variant="white" :header="attended" class="text-left chat-card">
      <div class="device-text-container">
        <span class="main-info">{{observations}}</span>
      </div>
    </b-card>
    <span class="subtext-info mb-4"><strong>{{user}}</strong>&nbsp;&nbsp;{{created_at}}</span>
  </div>
</template>
<script>
import { BCard } from 'bootstrap-vue'

export default {
  components: {
    BCard
  },
  props: {
    img: String,
    attended: String,
    observations: String,
    created_at: String,
    owner: String,
    user: String
  },
  computed: {
    variant () {
      return this.owner === 'm' ? 'primary' : 'secondary'
    },
    position () {
      return this.owner === 'm' ? 'd-flex align-items-end flex-column' : 'd-flex align-items-start flex-column'
    }
  },
  methods: {
    relativeTimeConvert (date) {
      date.replace(' ', 'T')
      return this.$dayjs().to(this.$dayjs(date))
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "@/assets/scss/spore-components/listChatItem.scss";
</style>
