<template>
  <b-card>
    <b-row>
      <div class="m-2">
        <b-button class="float-left" variant="primary" @click="back()">Regresar</b-button>
      </div>
    </b-row>
    <div>
      <b-row class="mt-1">

        <b-col md="6" lg="3">
          <b-card class="card_statisc">
            <b-row>
              <b-col md="12">
                <h4>{{$t('Labels.Type')}}</h4>
                <label class="font-medium-1 mb-1">{{(response.type == 'A' ? $t('Messages.Alert') : response.type == 'E' ? $t('Messages.Energy') : response.type == 'C' ? $t('Messages.Connection') : $t('Messages.Other')) }}</label>
                <h4>{{$t('Labels.AlertedOn')}}</h4>
                <label class="font-medium-1 mb-1">{{(response.created_at ? formDate(response.created_at, 'dayJs') : '')}}</label>
                <h4 v-if="response.user">{{$t('Labels.DisabledBy')}}</h4>
                <label class="font-medium-1 mb-1">{{(response.user ? response.user.first_name + ' ' + response.user.last_name : '')}}</label>
                <h4 v-if="response.disabled_at">{{$t('Labels.DisabledOn')}}</h4>
                <label class="font-medium-1 mb-1">{{(response.disabled_at ? formDate(response.disabled_at, 'dayJs') : '')}}</label><br>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col md="6" lg="8">
          <b-card bg-variant="light">
            <b-form class="m-2">
              <b-row class="mt-2">
                <b-col cols="12">
                  <b-form-group :label="$t('Labels.AttendedBy')" label-for="alias">
                    <b-form-input id="alias" v-model="attendedBy" name="alias" :placeholder="$t('Labels.AttendedBy')"/>
                  </b-form-group>
                  <b-form-group label="Comentario" label-for="name">
                    <b-form-textarea id="name" v-model="observationsAdd" trim no-resize rows="4" placeholder="Escriba sus comentarios..."></b-form-textarea>
                  </b-form-group>
                </b-col>
                <b-col cols="12" class="d-flex justify-content-end">
                  <div class="d-flex align-items-center">
                    <b-button variant="primary" @click="save()">Enviar</b-button>
                  </div>
                </b-col>
              </b-row>
            </b-form>
            <hr>
            <b-col style="overflow-y: auto !important; height: 550px !important;">
              <table id="table-contacts">
                <tr class="item-row item" v-for="(item, index) in panicButtonObs" :key="index" :data-uuid="item.uuid">
                  <td>
                    <listChatItem
                      :observations="item.observations"
                      :attended="item.attended"
                      :created_at="(item.created_at ? formDate(item.created_at, 'dayJs') : '')"
                      :owner="item.owner"
                      :user="item.user.first_name + ' ' + item.user.last_name"
                    />
                  </td>
                </tr>
              </table>
            </b-col>
          </b-card>
        </b-col>
        <br><br>
      </b-row>
    </div>

  </b-card>
</template>

<script>

import { timeZoneDate, formatDateLanguages } from '@/libs/tools/helpers'
import listChatItem from '@core/spore-components/listChatItem/listChatItem.vue'
import {
  BButton, BRow, BCol, BCard, BForm, BFormGroup, BFormInput, BFormTextarea, VBTooltip
} from 'bootstrap-vue'
import request from '@/libs/request/index'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    listChatItem
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data () {
    return {
      uuid: this.$route.params.uuid,
      alertUuid: this.$route.params.alertUuid,
      response: [],
      totalRows: 0,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 75, 100],
      perPage: 25,
      count: 0,
      attendedBy: '',
      observationsAdd: '',
      panicButtonObs: [],
      fields: [
        { key: 'owner', label: this.$t('Labels.Type') }
      ],
      filters: {
        limit: 20,
        offset: 0,
        byColumn: 'id',
        byOrder: 'DESC'
      }
    }
  },
  methods: {
    formDate (date, type) {
      return timeZoneDate(date, type)
    },
    format (date) {
      return formatDateLanguages(date, 'hour')
    },
    async getDetailAlarm () {
      const params = {
        url: `/panic_button/${this.alertUuid}/alerts/detail`,
        method: 'GET'
      }
      await request(params).then(data => {
        if (data.data.code === 200) {
          this.response = data.data.data
          this.getAlertComments()
        }
      })
    },
    async getAlertComments () {
      const params = {
        url: `/panic_button/${this.alertUuid}/notes/list`,
        method: 'GET',
        params: this.filters
      }
      await request(params).then(data => {
        if (data.data.code === 200) {
          this.panicButtonObs = data.data.data.rows
          this.totalRows = data.data.data.count
          this.count = data.data.data.rows.length
        }
      })
    },
    back () {
      this.$router.push(`/panic_button/sm/detail/${this.uuid}/history`)
    },
    async save () {
      const params = {
        url: `/panic_button/${this.alertUuid}/addNotes`,
        method: 'PUT',
        params: {
          attended: this.attendedBy,
          observations: this.observationsAdd
        }
      }
      await request(params).then(data => {
        this.attendedBy = ''
        this.observationsAdd = ''
        this.getAlertComments()
      })
    }
  },
  created () {
    this.getDetailAlarm()
  },
  watch: {
    currentPage (val) {
      this.filters.offset = (val - 1) * this.filters.limit
      this.getAlertComments()
    },
    byColumn (val) {
      this.filters.byColumn = val
      this.currentx = 1
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
